import React, { FC } from 'react'
import ColouredTextDiv from 'src/components/ui/ColouredTextDiv'
import ColouredBackgroundDiv from 'src/components/ui/ColouredBackgroundDiv'
import styled from 'styled-components'
import resolveConfig from 'tailwindcss/resolveConfig'
import tailwindConfig from 'src/../tailwind.config'
import tw from 'twin.macro'
import { CalendarGridEventData } from 'src/components/calendar/calendar-types'
import { CalendarColorMapping } from 'src/components/calendar/calendar-color-mapping'
import themeColors from 'src/helpers/theme-colors'
import { Link } from 'gatsby-plugin-react-i18next'

const tailwind = resolveConfig(tailwindConfig)

interface IIndentProps {
  leftMargin: string
}

const Header = styled.div<IIndentProps>`
  & strong,
  & b {
    font-weight: 500;
  }
  margin-left: ${({ leftMargin }) => leftMargin};
`
const RectangularWrapperDiv = styled.div`
  width: 100%;
  padding-top: 100%;
  position: relative;
`

const PaddedColouredBackgroundDiv = styled(ColouredBackgroundDiv)`
  padding: 5%;
  @media (min-width: 375px) {
    padding: 10%;
  }
  @media (min-width: ${tailwind.theme.screens.md}) {
    ${tw`p-8`}
  }
  @media (min-width: ${tailwind.theme.screens.xl}) {
    ${tw`p-7`}
  }
`

const Date = styled(ColouredTextDiv)<IIndentProps>`
  padding: 5%;
  @media (min-width: 375px) {
    padding: 10%;
  }
  @media (min-width: ${tailwind.theme.screens.md}) {
    ${tw`p-8`}
  }
  @media (min-width: ${tailwind.theme.screens.xl}) {
    ${tw`p-7`}
  }
  margin-left: ${({ leftMargin }) => leftMargin};
  margin-top: -0.18em;
`

const Description = styled.div`
  padding-left: 5%;
  padding-right: 5%;
  @media (min-width: 375px) {
    padding-left: 10%;
    padding-right: 10%;
  }
  @media (min-width: ${tailwind.theme.screens.md}) {
    ${tw`px-8`}
  }
  @media (min-width: ${tailwind.theme.screens.xl}) {
    ${tw`px-7`}
  }
`

const CalendarEventTile: FC<CalendarGridEventData> = ({
  dateHeader,
  dateHeaderIndent,
  header,
  headerIndent,
  shortDescription,
  location,
  colorScheme,
  newsItem,
}) => {
  const calendarColorMapping: CalendarColorMapping = {
    DARK_GRAY: {
      backgroundColor: themeColors.deepDarkBlue,
      dateColor: themeColors.white,
      contentColor: themeColors.brightYellow,
    },
    MEDIUM_GRAY: {
      backgroundColor: themeColors.grayBlue,
      dateColor: themeColors.white,
      contentColor: themeColors.brightYellow,
    },
    LIGHT_GRAY: {
      backgroundColor: themeColors.navyBlueGray,
      dateColor: themeColors.white,
      contentColor: themeColors.brightYellow,
    },
    YELLOW: {
      backgroundColor: themeColors.brightYellow,
      dateColor: themeColors.almostBlackBlue,
      contentColor: themeColors.almostBlackBlue,
    },
  }
  const { backgroundColor, dateColor, contentColor } = calendarColorMapping[
    colorScheme
  ]

  const getContent = () => (
    <PaddedColouredBackgroundDiv
      backgroundColor={backgroundColor}
      className="absolute inset-0 flex flex-col justify-center overflow-hidden"
    >
      <Date
        leftMargin={dateHeaderIndent}
        textColor={dateColor}
        className="text-xl sm:text-4xl font-extralight tracking-wider absolute left-0 top-0"
      >
        {dateHeader}
      </Date>
      <ColouredTextDiv textColor={contentColor}>
        <Header
          leftMargin={headerIndent}
          className="text-4.5xl sm:text-6xl md:text-7xl xl:text-6xl font-extralight self-end tracking-widest -mt-7 sm:mt-0 xl:-mt-3"
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: header }}
        />
        <Description className="leading-none sm:leading-tight text-base sm:text-lg xl:text-base font-light absolute inset-x-0 -mt-1.5 sm:mt-3 xl:mt-1">
          <span className="font-medium">{shortDescription} / </span>
          {location}
        </Description>
      </ColouredTextDiv>
    </PaddedColouredBackgroundDiv>
  )

  const getLinkedContent = () => (
    <Link to={`/news/${newsItem.slug}/`} className="block">
      {getContent()}
    </Link>
  )

  return (
    <RectangularWrapperDiv>
      {newsItem ? getLinkedContent() : getContent()}
    </RectangularWrapperDiv>
  )
}

CalendarEventTile.defaultProps = {
  dateHeaderIndent: '0',
  headerIndent: '0',
}

export default CalendarEventTile
