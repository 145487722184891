import tailwindConfig from 'src/../tailwind.config'
import resolveConfig from 'tailwindcss/resolveConfig'
import { TailwindColorConfig } from 'tailwindcss/tailwind-config'

export enum CalendarColorScheme {
  DARK_GRAY = 'DARK_GRAY',
  MEDIUM_GRAY = 'MEDIUM_GRAY',
  LIGHT_GRAY = 'LIGHT_GRAY',
  YELLOW = 'YELLOW',
}

export type CalendarColorSchemeDefinition = {
  backgroundColor: string
  dateColor: string
  contentColor: string
}

export type CalendarColorMapping = {
  [key in CalendarColorScheme]: CalendarColorSchemeDefinition
}

export type CustomColorConfig = TailwindColorConfig & {
  [key: string]: string
}

const colors = resolveConfig(tailwindConfig).theme.colors as CustomColorConfig

export const calendarColorMapping: CalendarColorMapping = {
  DARK_GRAY: {
    backgroundColor: colors.deepDarkBlue,
    dateColor: colors.white,
    contentColor: colors.brightYellow,
  },
  MEDIUM_GRAY: {
    backgroundColor: colors.grayBlue,
    dateColor: colors.white,
    contentColor: colors.brightYellow,
  },
  LIGHT_GRAY: {
    backgroundColor: colors.navyBlueGray,
    dateColor: colors.white,
    contentColor: colors.brightYellow,
  },
  YELLOW: {
    backgroundColor: colors.brightYellow,
    dateColor: colors.almostBlackBlue,
    contentColor: colors.almostBlackBlue,
  },
}
