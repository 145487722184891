import React, { FC, useState } from 'react'
import CalendarYearPicker from 'src/components/calendar/CalendarYearPicker'
import CalendarViewSelector from 'src/components/calendar/CalendarViewSelector'
import CalendarGrid from 'src/components/calendar/CalendarGrid'
import CalendarViewType from 'src/components/calendar/CalendarViewType'
import CalendarList from 'src/components/calendar/CalendarList'
import { useIsLargeScreen } from 'src/helpers/layout'
import { CalendarYearData } from 'src/components/calendar/calendar-types'

export enum CalendarColorScheme {
  DARK_GRAY = 'DARK_GRAY',
  MEDIUM_GRAY = 'MEDIUM_GRAY',
  LIGHT_GRAY = 'LIGHT_GRAY',
  YELLOW = 'YELLOW',
}

export type CalendarColorSchemeDefinition = {
  backgroundColor: string
  dateColor: string
  contentColor: string
}

export type CalendarColorMapping = {
  [key in CalendarColorScheme]: CalendarColorSchemeDefinition
}

interface ICalendarProps {
  years: CalendarYearData[]
}

const Calendar: FC<ICalendarProps> = ({ years }) => {
  const isLarge = useIsLargeScreen()
  const [year, setYear] = useState(new Date().getFullYear())
  let yearIndex = years.findIndex((it) => it.year >= year)
  if (yearIndex === -1) {
    yearIndex = years.length - 1
  }
  const yearData: CalendarYearData = years[yearIndex]
  const responsiveData = isLarge
    ? yearData.desktopGrid
    : yearData.mobileGrid

  const increaseYear = () => setYear(years[yearIndex + 1].year)
  const decreaseYear = () => setYear(years[yearIndex - 1].year)

  const [viewType, setViewType] = useState(CalendarViewType.GridView)
  const switchViewType = () =>
    setViewType(
      viewType === CalendarViewType.GridView
        ? CalendarViewType.ListView
        : CalendarViewType.GridView
    )

  return (
    <>
      <CalendarYearPicker
        year={year}
        isNext={years.length > yearIndex + 1}
        isPrevious={yearIndex > 0}
        onNextClicked={increaseYear}
        onPreviousClicked={decreaseYear}
      />
      <CalendarViewSelector viewType={viewType} onChange={switchViewType} />
      {viewType === CalendarViewType.GridView ? (
        <CalendarGrid data={responsiveData} />
      ) : (
        <CalendarList data={responsiveData} />
      )}
    </>
  )
}

export default Calendar
