import styled from 'styled-components'

export interface TextColorProps {
  readonly textColor: string
}

const ColouredTextDiv = styled.div<TextColorProps>`
  color: ${(props) => props.textColor};
`

export default ColouredTextDiv
