import React, { FC } from 'react'
import { CalendarGridEventData } from 'src/components/calendar/calendar-types'
import { calendarColorMapping } from 'src/components/calendar/calendar-color-mapping'
import ColouredTextDiv from 'src/components/ui/ColouredTextDiv'
import ColouredBackgroundDiv from 'src/components/ui/ColouredBackgroundDiv'
import styled from 'styled-components'
import resolveConfig from 'tailwindcss/resolveConfig'
import tailwindConfig from 'src/../tailwind.config'
import { Link } from 'gatsby-plugin-react-i18next'

const tailwind = resolveConfig(tailwindConfig)

const MobileCalendarListItemContainer = styled(ColouredBackgroundDiv)`
  min-height: 4.5rem;
  @media (min-width: ${tailwind.theme.screens.md}) {
    min-height: 6rem;
  }
`

const MobileCalendarListEntry: FC<CalendarGridEventData> = ({
  dateHeader,
  shortDescription,
  location,
  colorScheme,
  newsItem,
}) => {
  const { backgroundColor, dateColor, contentColor } = calendarColorMapping[
    colorScheme
  ]

  const getContent = () => (
    <>
      <MobileCalendarListItemContainer
        className="border-b border-almostBlackBlue flex items-stretch"
        backgroundColor={backgroundColor}
      >
        <ColouredTextDiv
          textColor={dateColor}
          className="w-3/12 md:w-1/5 text-xl sm:text-4xl font-thin flex items-center justify-center border-r border-almostBlackBlue"
        >
          {dateHeader}
        </ColouredTextDiv>
        <ColouredTextDiv
          textColor={contentColor}
          className="w-9/12 md:w-4/5 text-base sm:text-lg font-light leading-tight flex items-center justify-left py-3 px-3 md:px-6"
        >
          <span>
            <span className="font-medium inline">{shortDescription} / </span>
            {location}
          </span>
        </ColouredTextDiv>
      </MobileCalendarListItemContainer>
    </>
  )

  const getLinkedContent = () => (
    <Link to={`/news/${newsItem.slug}/`} className="block">
      {getContent()}
    </Link>
  )

  return newsItem ? getLinkedContent() : getContent()
}
export default MobileCalendarListEntry
