import * as React from 'react'
import Calendar from 'src/components/calendar/Calendar'
// import CalendarDocuments from 'src/components/calendar/CalendarDocuments' // TODO: uncomment when clear what to do with documents
import { Helmet } from 'react-helmet'
import Layout from 'src/components/layout'
import { graphql } from 'gatsby'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import ContentPageContainer from 'src/components/layout/ContentPageContainer'

const IndexPage = ({ pageContext: { years } }) => {
  const { t } = useTranslation('calendar')

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{t('Calendar')}</title>
        <meta
          name="description"
          content="The mission of The International Football Association Board (The IFAB) is to serve the world of football as the independent guardian of the Laws of the Game. As the only body authorised to decide and agree changes to the Laws of the Game, The IFAB consults with the football community, with the goal to improve and develop the game for players, match officials and fans while protecting and strengthening the spirit and simplicity of football."
        />
      </Helmet>
      <Layout>
        <div className="bg-white min-h-screen">
          <ContentPageContainer className="pb-12 md:pb-24">
            <Calendar years={years} />
            {
              // TODO: uncomment when clear what to do with documents
              /* <CalendarDocuments />
              items={model.relatedDocuments[0].relatedDocuments}
            /> */
            }
          </ContentPageContainer>
        </div>
      </Layout>
    </>
  )
}

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`

export default IndexPage
