import React, { FC } from 'react'
import { CalendarGridImageData } from 'src/components/calendar/calendar-types'

const CalendarImageTile: FC<CalendarGridImageData> = ({ image }) => (
  <>
    <div className="w-100 aspect-w-1 aspect-h-1">
      <img
        src={image.url}
        alt={image.alt}
        className="w-full h-full object-cover"
      />
    </div>
  </>
)

export default CalendarImageTile
