import React, { FC } from 'react'
import CalendarImageTile from 'src/components/calendar/CalendarImageTile'
import CalendarEventTile from 'src/components/calendar/CalendarEventTile'
import {
  CalendarGridEventData,
  CalendarGridImageData,
  CalendarGridItemData,
} from 'src/components/calendar/calendar-types'

interface ICalendarGridProps {
  data: CalendarGridItemData[]
}

const CalendarGrid: FC<ICalendarGridProps> = ({ data }) => {
  const getCalendarImageElement = (
    item: CalendarGridImageData
  ): JSX.Element => (
    <CalendarImageTile id={item.id} image={item.image} key={item.id} />
  )

  const getCalendarEventElement = (
    item: CalendarGridEventData
  ): JSX.Element => (
    <CalendarEventTile
      key={item.id}
      id={item.id}
      dateHeader={item.dateHeader}
      dateHeaderIndent={item.dateHeaderIndent ?? '0'}
      header={item.header}
      headerIndent={item.headerIndent ?? '0'}
      shortDescription={item.shortDescription}
      location={item.location}
      colorScheme={item.colorScheme}
      newsItem={item.newsItem}
    />
  )
  const isImage = (item: CalendarGridItemData): boolean =>
    Object.prototype.hasOwnProperty.call(item, 'image')

  const tiles: JSX.Element[] = (data || []).map((item) => {
    if (isImage(item)) {
      return getCalendarImageElement(item as CalendarGridImageData)
    }
    return getCalendarEventElement(item as CalendarGridEventData)
  })

  return (
    <>
      <div className="grid grid-cols-2 lg:grid-cols-3">{tiles}</div>
    </>
  )
}

export default CalendarGrid
