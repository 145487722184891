export interface NewsItemAnchor {
  slug: string
}

export interface CalendarGridEventData {
  id: string
  dateHeader: string
  dateHeaderIndent?: string
  header: string
  headerIndent?: string
  shortDescription: string
  location: string
  colorScheme: string
  newsItem?: NewsItemAnchor
}

export interface CalendarImageData {
  title?: string
  alt?: string
  url: string
}

export interface CalendarGridImageData {
  id: string
  image: CalendarImageData
}

export type CalendarGridItemData = CalendarGridEventData | CalendarGridImageData

export const isImage = (item: CalendarGridItemData): boolean =>
  Object.prototype.hasOwnProperty.call(item, 'image')

export interface CalendarYearData {
  year: number
  desktopGrid: CalendarGridItemData[]
  mobileGrid: CalendarGridItemData[]
}
