import React, { FC } from 'react'
import { calendarColorMapping } from 'src/components/calendar/calendar-color-mapping'
import ColouredTextDiv from 'src/components/ui/ColouredTextDiv'
import ColouredBackgroundDiv from 'src/components/ui/ColouredBackgroundDiv'
import { CalendarGridEventData } from 'src/components/calendar/calendar-types'
import { Link } from 'gatsby-plugin-react-i18next'

const CalendarListEntry: FC<CalendarGridEventData> = ({
  dateHeader,
  shortDescription,
  location,
  colorScheme,
  newsItem,
}) => {
  const { backgroundColor, dateColor, contentColor } = calendarColorMapping[
    colorScheme
  ]

  const getContent = () => (
    <>
      <ColouredBackgroundDiv
        className="flex items-stretch border-b border-almostBlackBlue"
        backgroundColor={backgroundColor}
      >
        <ColouredTextDiv
          textColor={dateColor}
          className="w-1/5 text-xl sm:text-5xl lg:text-4xl font-thin py-5 border-r border-almostBlackBlue flex justify-center items-center"
        >
          {dateHeader}
        </ColouredTextDiv>
        <ColouredTextDiv
          textColor={contentColor}
          className="w-3/5 text-lg xl:text-base font-medium border-r border-almostBlackBlue py-7 px-6 flex items-center"
        >
          <p>{shortDescription}</p>
        </ColouredTextDiv>
        <ColouredTextDiv
          textColor={contentColor}
          className="w-1/5 text-lg xl:text-base font-light py-7 flex justify-center items-center"
        >
          <p>{location}</p>
        </ColouredTextDiv>
      </ColouredBackgroundDiv>
    </>
  )

  const getLinkedContent = () => (
    <Link to={`/news/${newsItem.slug}/`} className="block">
      {getContent()}
    </Link>
  )

  return newsItem ? getLinkedContent() : getContent()
}
export default CalendarListEntry
