import styled from 'styled-components'

export interface BackgroundColorProps {
  readonly backgroundColor: string
}

const ColouredBackgroundDiv = styled.div<BackgroundColorProps>`
  background: ${(props) => props.backgroundColor};
`

export default ColouredBackgroundDiv
