// i18next-extract-mark-ns-start calendar

import React, { FC } from 'react'
import CalendarViewType from 'src/components/calendar/CalendarViewType'
import LabeledSwitch from 'src/components/ui/LabeledSwitch'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import ThinHeader from 'src/components/layout/ThinHeader'

interface ICalendarViewSelectorProps {
  viewType: CalendarViewType
  onChange: () => void
}

const CalendarViewSelector: FC<ICalendarViewSelectorProps> = ({
  viewType,
  onChange,
}) => {
  const { t } = useTranslation('calendar')

  return (
    <>
      <ThinHeader className="flex justify-center">
        <LabeledSwitch
          isEnabled={viewType === CalendarViewType.ListView}
          onChange={onChange}
          leftLabel={t('Grid view')}
          rightLabel={t('List view')}
          noPadding
          isGrayBackground
        />
      </ThinHeader>
    </>
  )
}

export default CalendarViewSelector
