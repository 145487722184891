import React, { FC } from 'react'
import { Arrow } from 'src/components/icons/ArrowIcon'
import { DynamicallyStyledPageTitle } from 'src/components/typography/PageTitle'

const PREVIOUS_NEXT_ARROW_CLASS =
  'w-6 h-6 sm:w-8 sm:h-8 md:w-9 md:h-9 border-t-2 border-r-2 border-deepDarkBlue'

interface ICalendarYearPickerProps {
  year: number
  isNext: boolean
  isPrevious: boolean
  onNextClicked: () => void
  onPreviousClicked: () => void
}

const CalendarYearPicker: FC<ICalendarYearPickerProps> = ({
  year,
  isNext,
  isPrevious,
  onNextClicked,
  onPreviousClicked,
}) => (
  <DynamicallyStyledPageTitle isSticked={false}>
    <div className="w-full flex flex-row justify-between">
      <button
        type="button"
        className={isPrevious ? '' : 'invisible'}
        onClick={onPreviousClicked}
      >
        <Arrow
          className={PREVIOUS_NEXT_ARROW_CLASS}
          rotate="225deg"
          shift="20%, 0"
        />
      </button>

      <span className="mx-2">{year}</span>
      <button
        type="button"
        className={isNext ? '' : 'invisible'}
        onClick={onNextClicked}
      >
        <Arrow
          className={PREVIOUS_NEXT_ARROW_CLASS}
          rotate="45deg"
          shift="-20%, 0"
        />
      </button>
    </div>
  </DynamicallyStyledPageTitle>
)

export default CalendarYearPicker
