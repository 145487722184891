import React, { FC } from 'react'
import {
  CalendarGridEventData,
  CalendarGridItemData,
  isImage,
} from 'src/components/calendar/calendar-types'
import CalendarListEntry from 'src/components/calendar/CalendarListEntry'
import MobileCalendarListEntry from 'src/components/calendar/MobileCalendarListEntry'
import { useIsLargeScreen } from 'src/helpers/layout'

interface ICalendarListProps {
  data: CalendarGridItemData[]
}

const CalendarList: FC<ICalendarListProps> = ({ data }) => {
  const isLarge = useIsLargeScreen()
  const calendarEntries: JSX.Element[] = data
    .filter((entry) => !isImage(entry))
    .map((entry) => {
      const listEntry = entry as CalendarGridEventData
      if (isLarge)
        return (
          <CalendarListEntry
            key={listEntry.id}
            id={listEntry.id}
            dateHeader={listEntry.dateHeader}
            header={listEntry.header}
            shortDescription={listEntry.shortDescription}
            location={listEntry.location}
            colorScheme={listEntry.colorScheme}
            newsItem={listEntry.newsItem}
          />
        )
      return (
        <MobileCalendarListEntry
          key={listEntry.id}
          id={listEntry.id}
          dateHeader={listEntry.dateHeader}
          header={listEntry.header}
          shortDescription={listEntry.shortDescription}
          location={listEntry.location}
          colorScheme={listEntry.colorScheme}
          newsItem={listEntry.newsItem}
        />
      )
    })

  return <>{calendarEntries}</>
}

export default CalendarList
